<template>
  <v-container>
    <v-card outlined elevation="0">

      <div class="mt-4">
        <ItcannerHeader/>
      </div>
      <div>
        <TotalWithdrawal />
      </div>
      <!-- <v-divider></v-divider> -->
      <br><br>
      <div class="withdrawal-request text-center h3 mb-5">
        Request for Withdrawal
      </div>

      <div class="text-center">
      <div class="success-message">
        Name! Your new request #{{$route.params.orderId}} for withdrawal has been placed successfully<br>
        Hold tight! We will update your wallet shortly.
      </div>
        <br>
        <div class="terms-and-condition">
          Read our Terms and Conditions for Withdrawal requests here.
        </div>
        <br>
        <v-card-actions>
          <v-btn height="50" @click="$router.push({ name: 'Wallet' })" depressed color="success" class="mx-auto" rounded width="200">
            Back
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-container>
</template>
<script>
import ItcannerHeader from "../ItcannerHeader.vue"
import TotalWithdrawal from "./TotalWithdrawal.vue"
export default ({
    components:{
        ItcannerHeader,
        TotalWithdrawal
    },
    data() {
        return {
            orderId:'',
        }
    },
    
})
</script>

<style>
.withdrawal-request{
    color: #36cdc5 !important;
}
.terms-and-condition{
  font-size: 13px;
}
.success-message{
    font-size: 20px;
    font-weight: bold;
}
</style>
